import { FC } from "react";
import { ChevronRightIcon } from "@primer/octicons-react";
import { Link } from "react-router-dom";

interface ArticleProps {
  data: {
    title: string;
    desc: string;
    date: string;
    path: string;
    external?: any;
    tags: string[];
  };
}

const Article: FC<ArticleProps> = ({ data }) => {
  const { title, desc, date, path, tags, external } = data;
  return (
    <section className="card fadein">
      <div className="card-title">
        <span className="card-title-text" style={{ marginRight: 5 }}>
          {title}
        </span>
        <span className="tag-marker">{tags[0]}</span>
      </div>
      <div className="card-desc">{desc}</div>
      <div className="card-footer">
        <Link
          className="shade-btn"
          to={external ? { pathname: path } : `/articles${path}`}
          target={external ? "_blank" : ""}
          rel="noreferrer"
        >
          <span className="shade-btn-text">Read Article</span>{" "}
          <span className="shade-btn-icon">
            <ChevronRightIcon />
          </span>
        </Link>
        &nbsp;&nbsp;|<span className="card-date">{date}</span>
        <span
          className="marker"
          style={{ backgroundColor: "rgb(57, 199, 157)" }}
        ></span>
      </div>
    </section>
  );
};

export default Article;
