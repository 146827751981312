import { CSSProperties } from "react";
import { useLocation } from "react-router-dom";
import { SearchIcon } from "@primer/octicons-react";
import "../../assets/styles/search.css";

const Search = ({
  placeHolder = "Search...",
  style = {},
  handleChange = () => null,
}: {
  placeHolder?: string;
  style?: CSSProperties;
  handleChange?: (value: any) => void;
}) => {
  // Get the location => pathname...
  const pathname = useLocation().pathname;
  return (
    <span
      style={
        pathname === "/talks" ||
        pathname === "/articles" ||
        pathname === "/talks/" ||
        pathname === "/articles/"
          ? style
          : { display: "none" }
      }
      className="search-container">
      <span className="search-icon">
        <SearchIcon />
      </span>
      <input
        className="search-input"
        placeholder={placeHolder}
        onChange={handleChange}
      />
    </span>
  );
};

export default Search;
