import { useContext, useEffect, useState } from "react";
import { Sidebar, Talk, TopNav } from "../../components";
import { MainContext } from "../../contexts";

const Talks = () => {
  const { talks } = useContext(MainContext);
  const [list, setList] = useState<any>(null);
  const data = list ? list : talks;
  const TalkCards = data.map((item: any) => (
    <Talk key={item.title} data={item} />
  ));

  useEffect(() => {
    document.title = "Talks - Maye Edwin";
  }, []);

  return (
    <section className="page-parent">
      <section className="home">
        <section className="left">
          <Sidebar />
        </section>
        <section className="right fade-in">
          <TopNav
            setList={setList}
            title="Talks"
            style={{
              maxWidth: "100%",
              top: 0,
              boxSizing: "border-box",
              boxShadow: "none",
              borderRadius: 0,
              borderRight: 0,
              borderLeft: 0,
              borderTop: 0,
            }}
          />
          <section className="articles fadein">
            {" "}
            {TalkCards.length > 0 ? (
              TalkCards
            ) : (
              <div className="no-results fadein">No results found</div>
            )}
          </section>
        </section>
      </section>
    </section>
  );
};

export default Talks;
