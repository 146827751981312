import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpRightIcon,
} from "@primer/octicons-react";
import { SetStateAction, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const splits = [
  {
    id: "1",
    path: "intro",
    blurb: "Learn about me",
    meta: {
      title: "About",
      component: () => (
        <div>
          <div
            style={{
              marginTop: 5,
            }}>
            My work ethic is driven by a passion for creating user-centric
            products that enhance people's lives. As an engineer, I am
            constantly striving to make a tangible impact through my work.
            Seeing the positive results of my efforts is what inspires and
            motivates me every day.
          </div>
          <div className="about-info">
            Senior Software Engineer at{" "}
            <a href="https://taxually.com" target="_blank" rel="noreferrer">
              Taxually Kft.
            </a>
          </div>
        </div>
      ),
      image: "https://placehold.it/300x300",
    },
    more: false,
  },
  {
    id: "2",
    path: "/articles",
    external: "https://read.pwafire.org/",
    blurb: "Read my articles",
    meta: {
      title: "Articles",
      component: () => (
        <div>
          I write articles about web technologies, developer communities and
          sometimes a little bit of short engineering gists. You can read them{" "}
          <Link to="/articles">here</Link>. I now write articles on{" "}
          <a href="https://read.pwafire.org/" target="_blank" rel="noreferrer">
            read.pwafire.org
          </a>
          .
        </div>
      ),
      image: "https://placehold.it/300x300",
    },
    more: true,
  },
  {
    id: "3",
    path: "/talks",
    blurb: "See my talks",
    meta: {
      title: "Talks",
      component: () => (
        <div>
          My talks cut accross web technologies, stuff like perf, capability,
          engineering practices and developer communities. Find my next and/or
          previous talks <Link to="/talks">here</Link>.
        </div>
      ),
      image: "https://placehold.it/300x300",
    },
    more: true,
  },
  {
    id: "4",
    path: "/projects",
    external: "https://github.com/mayeedwin",
    blurb: "See my projects",
    meta: {
      title: "Projects",
      component: () => (
        <div>
          <div>
            {" "}
            I've worked on a few open-source projects such as{" "}
            <a
              className="homepage-link"
              href="https://docs.pwafire.org"
              target="_blank"
              rel="noreferrer">
              pwafire
            </a>
            , which I maintain, and contributed to a few others like{" "}
            <b>typescript</b>. Most of my work is open-source and publicly
            available on{" "}
            <a
              className="homepage-link"
              href="https://github.com/mayeedwin"
              target="_blank"
              rel="noreferrer">
              github
            </a>
            .
          </div>
          <div style={{ marginTop: 10 }}>
            One of my current projects is{" "}
            <a
              className="homepage-link"
              href="https://read.pwafire.org"
              target="_blank"
              rel="noreferrer">
              read.pwafire.org
            </a>{" "}
            blog. I will be moving most of my content to this blog. Any new web
            content will be added to this blog.
          </div>
        </div>
      ),
      image: "https://placehold.it/300x300",
    },
    more: true,
  },
];

const Home = () => {
  // States...
  const [showDetails, setShowDetails] = useState(false);
  const [selectedSplit, setSelectedSplit] = useState(splits[0]);
  // Effect to set page title...
  useEffect(() => {
    document.title = "Home - Maye Edwin";
  }, []);

  // Handle click on a split...
  const handleSplitClick = (
    split: SetStateAction<{
      id: string;
      path: string;
      external?: string;
      blurb: string;
      meta: { title: string; component: () => JSX.Element; image: string };
      more: boolean;
    }>
  ) => {
    setSelectedSplit(split);
    setShowDetails(true);
  };

  // Render...
  return (
    <section className="home-page">
      <section className="home-box-grid-container">
        <section className="home-page-intro">
          <img
            loading="lazy"
            src="https://avatars3.githubusercontent.com/u/17247358?v=4"
            alt="M"
            title="Maye Edwin"
            className="home-page-intro-avatar"
          />
          <div className="home-page-intro-bio">
            <div className="home-page-title">
              <span>Maye Edwin</span>
              <span className="home-verified">
                <svg
                  viewBox="0 0 24 24"
                  width="28"
                  role="img"
                  aria-label="Verified icon">
                  <path
                    d="M22.25 12c0-1.43-.88-2.67-2.19-3.34.46-1.39.2-2.9-.81-3.91s-2.52-1.27-3.91-.81c-.66-1.31-1.91-2.19-3.34-2.19s-2.67.88-3.33 2.19c-1.4-.46-2.91-.2-3.92.81s-1.26 2.52-.8 3.91c-1.31.67-2.2 1.91-2.2 3.34s.89 2.67 2.2 3.34c-.46 1.39-.21 2.9.8 3.91s2.52 1.26 3.91.81c.67 1.31 1.91 2.19 3.34 2.19s2.68-.88 3.34-2.19c1.39.45 2.9.2 3.91-.81s1.27-2.52.81-3.91c1.31-.67 2.19-1.91 2.19-3.34zm-11.71 4.2L6.8 12.46l1.41-1.42 2.26 2.26 4.8-5.23 1.47 1.36-6.2 6.77z"
                    fill="#1da1f2"></path>
                </svg>
              </span>
            </div>
            <div className="home-official">
              <svg
                viewBox="0 0 24 24"
                width="16"
                role="img"
                aria-label="Verified icon">
                <path
                  d="M22.25 12c0-1.43-.88-2.67-2.19-3.34.46-1.39.2-2.9-.81-3.91s-2.52-1.27-3.91-.81c-.66-1.31-1.91-2.19-3.34-2.19s-2.67.88-3.33 2.19c-1.4-.46-2.91-.2-3.92.81s-1.26 2.52-.8 3.91c-1.31.67-2.2 1.91-2.2 3.34s.89 2.67 2.2 3.34c-.46 1.39-.21 2.9.8 3.91s2.52 1.26 3.91.81c.67 1.31 1.91 2.19 3.34 2.19s2.68-.88 3.34-2.19c1.39.45 2.9.2 3.91-.81s1.27-2.52.81-3.91c1.31-.67 2.19-1.91 2.19-3.34zm-11.71 4.2L6.8 12.46l1.41-1.42 2.26 2.26 4.8-5.23 1.47 1.36-6.2 6.77z"
                  fill="#57606a"></path>
              </svg>
              <span className="home-official-label">Official</span>
            </div>
            <p className="home-page-desc-text">
              Software Engineer and{" "}
              <a
                href="https://developers.google.com/community/experts/directory/profile/profile-edwin-maye"
                target="_blank"
                rel="noreferrer">
                Google Developer Expert
              </a>{" "}
              in Web Technologies.
            </p>
          </div>
        </section>
        {showDetails ? (
          <section className="home-box-details">
            <div
              className="home-box-details-title"
              onClick={() => setShowDetails(false)}>
              <ArrowLeftIcon size={24} />{" "}
              <span>{selectedSplit.meta.title}</span>
            </div>
            <div className="home-box-details-desc fadein">
              {selectedSplit.meta.component()}
            </div>
            <div className="home-box-details-footer">
              {selectedSplit.more ? (
                selectedSplit.external ? (
                  <a
                    className="home-box-details-footer-link"
                    href={selectedSplit.external}
                    target="_blank"
                    rel="noreferrer">
                    {" "}
                    <span>See More</span>
                    <ArrowRightIcon size={24} />
                  </a>
                ) : (
                  <Link
                    className="home-box-details-footer-link"
                    to={selectedSplit.path}>
                    {" "}
                    <span>See More</span>
                    <ArrowRightIcon size={24} />
                  </Link>
                )
              ) : null}
            </div>
          </section>
        ) : (
          <section className="home-box-grid fadein">
            {splits.map((split) => (
              <div
                key={split.id}
                className="home-box-grid-item first-grid-cell"
                onClick={() => handleSplitClick(split)}>
                <div className="home-box-grid-item-title">
                  <span> {split.meta.title}</span>{" "}
                  <ArrowUpRightIcon size={24} />
                </div>
                <div className="home-box-grid-item-desc">{split.blurb}</div>
              </div>
            ))}
          </section>
        )}
        <section style={{ marginTop: 15, fontSize: 13 }}>
          Copyright {new Date().getFullYear()} Maye Edwin. All rights reserved.
        </section>
      </section>
    </section>
  );
};

export default Home;
