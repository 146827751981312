import { Helmet } from "react-helmet-async";

interface metaInterface {
  banner?: string;
  canonical?: string;
  title?: string;
  author?: string;
  description?: string;
  keywords?: string;
}
const Meta = ({
  banner = `%PUBLIC_URL%//assets/images/banners/mayeedwin.jpg`,
  canonical = `https://maye.pwafire.org`,
  title = `Maye Edwin - Software Engineer & Speaker`,
  author = `Maye Edwin`,
  description = `Maye Edwin is a Sofware Engineer and Speaker based in Nairobi, Kenya.`,
  keywords = `Who is Maye Edwin, Maye, Edwin, Developer, Web, Progressive Web Apps, Engineer, Engineering, Software Developer, Google Developers, GDG,
  GDG Communities, GDG Eldoret, PWA Fire, PWA, JavaScript, HTML5, CSS, Jenga CSS, Kenya, Africa, Nairobi, Eldoret`,
}: metaInterface) => {
  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={canonical} />
      <meta name="author" content={author} />
      <meta name="copyright" content="Maye Edwin" />
      <meta name="description" content={description} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content="https://maye.pwafire.org" />
      <meta name="twitter:site" content="@mayeedwin1" />
      <meta name="twitter:creator" content="@mayeedwin1" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content="https://maye.pwafire.org" />
      <meta property="og:image" content={banner} />
      <meta property="og:site_name" content={title} />
      <meta property="og:description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};
export default Meta;
