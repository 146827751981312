import { FC } from "react";
import { SmileyIcon } from "@primer/octicons-react";
import { NavLink } from "react-router-dom";
import "../../assets/styles/sidebar.css";

interface SideBarProps {
  display?: boolean;
}

const Sidebar: FC<SideBarProps> = () => {
  return (
    <section className="inner-nav">
      <section className="side-top">
        <img
          className="side-avatar"
          src="https://avatars3.githubusercontent.com/u/17247358?v=4"
          alt="M"
          title="Maye Edwin"
        />
        <div>
          <div className="side-name">Maye Edwin</div>
          <div className="side-email">
            <div className="side-email-text">Software Engineer</div>
          </div>
        </div>
      </section>
      <div className="nav-items">
        <NavLink
          to="/articles"
          className={({ isActive }) =>
            isActive ? "nav-item active" : "nav-item"
          }
          replace>
          Articles
        </NavLink>
        <NavLink
          to="/talks"
          className={({ isActive }) =>
            isActive ? "nav-item active" : "nav-item"
          }>
          Talks
        </NavLink>
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive ? "nav-item active" : "nav-item"
          }
          replace>
          Exit
        </NavLink>

        <section className="social">
          <div className="social-blurb">
            Let's have a chat...
            <SmileyIcon />
          </div>
          <a
            className="social-link"
            href="https://twitter.com/mayeedwin1"
            target="_blank"
            rel="noreferrer">
            Twitter
          </a>

          <a
            className="social-link"
            href="https://github.com/mayeedwin"
            target="_blank"
            rel="noreferrer">
            GitHub
          </a>
        </section>
      </div>
    </section>
  );
};

export default Sidebar;
