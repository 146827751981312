import { useEffect, useRef, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import Prism from "prismjs";
import ReactMarkdown from "react-markdown";
import { ClockIcon } from "@primer/octicons-react";
import { useParams } from "react-router-dom";
import gfm from "remark-gfm";
import docsdata from "../../articles/docs.json";
import { Meta, TopNav } from "../../components";
import "../../assets/styles/doc.css";
import "../../assets/styles/prism.css";
import "../../assets/styles/carbon.css";

const Markdown = () => {
  // State...
  const [content, setContent] = useState("");
  const [titleText, setTitleText] = useState("Article");
  const [meta, setMeta] = useState({
    title: "",
    desc: "",
    canonical: "https://maye.pwafire.org/articles/",
    date_modified: "",
  });

  // Router...
  const params = useParams();

  // Ref...
  const docRef = useRef<any>(null);
  let current: any = null;
  if (docRef.current) {
    current = docRef.current;
  }

  useEffect(() => {
    const name = params.name || "";
    const found = docsdata.find(doc => doc.name === name);
    if (found) {
      // Date formatter...
      const dateString = new Date(found.date_modified);
      setContent(found.content);
      setMeta((prev: any) => {
        return {
          ...prev,
          date_modified: dateString.toLocaleDateString(undefined, {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
          }),
        };
      });
    }
  }, [params]);

  useEffect(() => {
    Prism.highlightAll();
  });

  useEffect(() => {
    if (current && current?.children.length > 2) {
      const title = current.children[1];
      const desc = current.children[2];
      title.setAttribute("class", "hide");
      desc.setAttribute("class", "hide");
      const nodeNameTitle = title.nodeName;
      const nodeNameDesc = desc.nodeName;
      if (nodeNameTitle === "H2") {
        setTitleText(title.textContent);
        setMeta((prev: any) => {
          return {
            ...prev,
            canonical: `https://maye.pwafire.org/articles/${params.name}`,
            title: `${title.textContent} - Maye Edwin`,
          };
        });
      }
      if (nodeNameDesc === "P") {
        setMeta((prev: any) => {
          return {
            ...prev,
            desc: desc.textContent,
          };
        });
      }
    }
  }, [current, params.name]);

  // Remove page title and meta description...
  useEffect(() => {
    // Remove meta description...
    document.querySelector("meta[name=description]")?.remove();
    // Set meta description...
    const metaDescription = "Articles by Maye Edwin on the web.";
    document
      .querySelector("meta[name=description]")
      ?.setAttribute("content", metaDescription);
  }, []);

  const carbonads = useRef(null);

  useEffect(() => {
    if (carbonads.current) {
      const script = document.createElement("script");
      script.src =
        "https://cdn.carbonads.com/carbon.js?serve=CE7D55QI&placement=pwafireorg";
      script.async = true;
      script.id = "_carbonads_js";
      script.type = "text/javascript";
      script.defer = true;
      const el: any = carbonads.current;
      el.appendChild(script);
      return () => {
        el.removeChild(script);
      };
    }
  }, [carbonads]);

  return (
    <HelmetProvider>
      <Meta
        title={meta.title}
        description={meta.desc}
        canonical={meta.canonical}
      />
      <main>
        <section className="article-page">
          <section className="article-banner">
            <div className="article-banner-title">{titleText}</div>
            <div className="article-date">
              <span>
                {" "}
                <ClockIcon />
                <span style={{ marginLeft: 5 }}>{meta.date_modified}</span>
              </span>
            </div>
          </section>
          <section className="article-section">
            {/* <div ref={carbonads}></div> */}
            <article ref={docRef} className="post">
              <div className="date hide">
                <span>
                  {" "}
                  <ClockIcon />
                  <span style={{ marginLeft: 5 }}>{meta.date_modified}</span>
                </span>
              </div>
              <ReactMarkdown remarkPlugins={[gfm]} children={content} />
              <p className="quote">
                Got any question? You wanna have a chat?{" "}
                <a
                  href="https://twitter.com/mayeedwin1"
                  target="_blank"
                  rel="noopener noreferrer">
                  Hit my inbox on twitter asap 😉
                </a>
              </p>
            </article>
          </section>
        </section>
        <TopNav prev="/articles/" />
      </main>
    </HelmetProvider>
  );
};

export default Markdown;
