import React, { FC } from "react";

interface TalkProps {
  data: {
    title: string;
    desc: string;
    date: string;
    video?: string;
    url?: string;
    location: string;
    external?: any;
    tags: string[];
  };
}

const Talk: FC<TalkProps> = ({ data }) => {
  const { title, desc, date, tags, video, location } = data;
  return (
    <section className="talk-component fadein">
      <section className="date">
        <span
          className="marker"
          style={
            tags[0] === "featured" || tags[0] === "pinned"
              ? { backgroundColor: "rgb(243, 154, 39)" }
              : { display: "none" }
          }
        ></span>
        <span className="date-text">
          {date}&nbsp;&nbsp;{location}&nbsp;
        </span>
        <span
          style={tags[0] === "featured"  || tags[0] === "pinned" ? {} : { display: "none" }}
          className="tag-marker"
        >
          {tags[0]}&nbsp;
        </span>
      </section>
      <section>
        <div className="talk-title">{title}</div>
        <section className="talk-desc">
          {desc}
          <span style={video ? {} : { display: "none" }}>
            <a
              href={video}
              target="_blank"
              rel="noreferrer"
              style={{ marginLeft: 3 }}
            >
              (Video Recording)
            </a>
          </span>{" "}
        </section>
      </section>
    </section>
  );
};

export default Talk;
