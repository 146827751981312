import { createContext, ReactChild, ReactFragment, ReactPortal } from "react";
import articles from "../data/articles.json";
import talks from "../data/talks.json";

const MainContext = createContext({ articles, talks });

const MainContextProvider = (props: {
  children: ReactChild | ReactFragment | ReactPortal | any;
}) => {
  return (
    <MainContext.Provider value={{ articles, talks }}>
      {props.children}
    </MainContext.Provider>
  );
};

export { MainContextProvider, MainContext };
