import { CSSProperties, FC, useContext, useEffect, useState } from "react";
import { ArrowLeftIcon } from "@primer/octicons-react";
import { Link } from "react-router-dom";
import { Search } from "..";
import "../../assets/styles/topNav.css";
import { MainContext } from "../../contexts";
import { filtered } from "filtered";
interface TopNavProps {
  prev?: string;
  title?: string;
  style?: CSSProperties;
  setList?: any;
}

const TopNav: FC<TopNavProps> = ({
  prev,
  title = "Articles",
  style = {},
  setList,
}) => {
  const { articles, talks } = useContext(MainContext);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    title.toLocaleLowerCase() === "articles"
      ? setData(articles)
      : setData(talks);
  }, [articles, talks, title]);

  const handleChange = (event: any) => {
    event.preventDefault();
    const result = filtered({
      data: data,
      firestore: false,
      filter: "title",
      query: event.target.value,
    });
    setList(result);
  };
  // Render...
  return (
    <section className="top-nav" style={style}>
      <div className="top-items">
        <Link className="back" to={prev ? prev : "/"}>
          <ArrowLeftIcon size={24} /> &nbsp; <span>Back</span>
        </Link>

        <span className="topics">
          <span>./{title}</span>
        </span>
        <Search
          handleChange={handleChange}
          style={{ marginLeft: 20, float: "right" }}
          placeHolder={`Search ${title.toLowerCase()}...`}
        />
      </div>
    </section>
  );
};

export default TopNav;
