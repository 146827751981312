import { useContext, useEffect, useState } from "react";
import { ArrowRightIcon, GiftIcon } from "@primer/octicons-react";
import { Article, Sidebar, TopNav } from "../../components";
import { MainContext } from "../../contexts";

const Articles = () => {
  // Context...
  const { articles } = useContext(MainContext);
  // State...
  const [list, setList] = useState<any>(null);
  // Set page title...
  useEffect(() => {
    // Set the page title...
    document.title = "Articles - Maye Edwin";
    document.querySelector("meta[name=description]")?.remove();
    // Remove meta description...
    const metaDescription = "Articles by Maye Edwin on the web.";
    // Set meta description...
    document
      .querySelector("meta[name=description]")
      ?.setAttribute("content", metaDescription);
  }, []);
  // Get data...
  const data = list ? list : articles;
  const ArticleCards = data.map((item: any) => (
    <Article key={item.title} data={item} />
  ));
  return (
    <section className="page-parent">
      <section className="home">
        <section className="left">
          <Sidebar />
        </section>
        <section className="right fade-in">
          <TopNav
            setList={setList}
            title="Articles"
            style={{
              maxWidth: "100%",
              top: 0,
              boxSizing: "border-box",
              boxShadow: "none",
              borderRadius: 0,
              borderRight: 0,
              borderLeft: 0,
              borderTop: 0,
            }}
          />
          <section style={{ display: "none" }} className="maye-ads">
            <GiftIcon />
            <span className="active" style={{ marginRight: 5, marginLeft: 10 }}>
              {" "}
              Hey there, I just announced a new npm pkg
            </span>{" "}
            :{" "}
            <a
              style={{ marginLeft: 5 }}
              href="https://www.npmjs.com/package/filtered"
              target="_blank"
              rel="noreferrer">
              filtered - for firebase <ArrowRightIcon />
            </a>
          </section>
          <section className="articles fadein">
            {ArticleCards.length > 0 ? (
              ArticleCards
            ) : (
              <div className="no-results fadein">No results found</div>
            )}
          </section>
        </section>
      </section>
    </section>
  );
};

export default Articles;
